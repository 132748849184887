import useBackControl from "@core/hooks/useBackControl";
import Button from "@core/ui/Button";
import ArrowLeftIcon from "@heroicons/react/outline/ArrowLeftIcon";
import cn from "classnames";
import Link from "next/link";
import { forwardRef, ReactElement, ReactNode, useCallback } from "react";

import Container from "./Container";

interface ContainerPageWithHeadingProps {
  title?: string;
  children: ReactNode;
  hideBackButton?: boolean;
  onClickBack?: () => void;
  // TODO: Use a polymorphic type instead
  useH1Element?: boolean;
  className?: string;
  afterTitle?: ReactElement;
  shouldShowHelpLink?: boolean;
}

const ContainerPageWithHeading = forwardRef<
  HTMLDivElement,
  ContainerPageWithHeadingProps
>(
  (
    {
      title,
      children,
      hideBackButton,
      useH1Element = true,
      afterTitle,
      className,
      shouldShowHelpLink = false,
      onClickBack,
    },
    ref
  ) => {
    const defaultBack = useBackControl();

    const handleClickBack = useCallback(() => {
      if (typeof onClickBack !== "undefined") {
        return onClickBack();
      }

      return defaultBack();
    }, [defaultBack, onClickBack]);

    return (
      <Container
        ref={ref}
        className={cn("flex w-full flex-1 py-6 laptop:py-9", className)}
      >
        <main className="max-w-full flex-1">
          {!afterTitle &&
          !shouldShowHelpLink &&
          hideBackButton &&
          !title ? null : (
            <div className="mb-4 flex w-full flex-wrap items-center justify-between laptop:mb-6">
              <div className="flex items-center space-x-1 tablet:space-x-2 laptop:space-x-2.5">
                {!hideBackButton && (
                  <button
                    type="button"
                    onClick={handleClickBack}
                    className="rounded-full p-2 hover:bg-gray-200 hover:bg-opacity-30 focus:bg-gray-200 focus:bg-opacity-30"
                  >
                    <ArrowLeftIcon className="h-4 w-4 tablet:h-5 tablet:w-5 laptop:h-6 laptop:w-6" />
                  </button>
                )}

                {!!title && useH1Element && (
                  <h1 className="text-16px font-bold tablet:text-20px laptop:text-24px">
                    {title}
                  </h1>
                )}

                {!!title && !useH1Element && (
                  <div className="text-16px font-bold tablet:text-20px laptop:text-24px">
                    {title}
                  </div>
                )}
              </div>

              {afterTitle}

              {shouldShowHelpLink ? (
                <div className="ml-auto flex flex-1 flex-grow justify-end self-start tablet:mt-2">
                  <Link href="/support" passHref>
                    <Button as="a" className="font-bold" variant="text">
                      Need help?
                    </Button>
                  </Link>
                </div>
              ) : null}
            </div>
          )}

          {children}
        </main>
      </Container>
    );
  }
);

ContainerPageWithHeading.displayName = "ContainerPageWithHeading";

export default ContainerPageWithHeading;
