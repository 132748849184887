import cn from "classnames";
import { ForwardedRef, forwardRef, ReactElement, RefAttributes } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";

import FieldLengthCounter, {
  Props as CounterProps,
} from "../FieldLengthCounter";

interface OwnProps {
  variant?: "outlined" | "filled";
  backgroundColor?: "gray" | "white";
  label: string | Element;
  labelSuffix?: "required" | "optional";
  hideLabel?: boolean;
  id: string;
  helperText?: string;
  errorMessage?: string;
  hasError?: boolean;
  /**
   * Style individual pieces of the component
   */
  classes?: {
    root?: string;
    label?: string;
    error?: string;
    input?: string;
    helperText?: string;
  };
  disablePadding?: boolean;
  disableDefaultStyles?: boolean;
  showCounter?: boolean;
}

type TheirProps = TextareaAutosizeProps & RefAttributes<HTMLTextAreaElement>;

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = Omit<TheirProps, "className"> &
  Omit<OwnProps, "classNames"> &
  Partial<CounterProps<TFieldValues, TFieldName>>;

function TextareaComponent<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  classes,
  errorMessage,
  helperText,
  name,
  label,
  labelSuffix,
  hideLabel = false,
  variant = "filled",
  backgroundColor = "gray",
  disabled = false,
  disablePadding = false,
  hasError: hasErrorProp = false,
  disableDefaultStyles = false,
  maxLength,
  showCounter,
  inputRef: ref,
  ...rest
}: Props<TFieldValues, TFieldName> & {
  inputRef?: ForwardedRef<HTMLTextAreaElement>;
}): ReactElement {
  const hasError = !!errorMessage || hasErrorProp;
  const errorMessageId = `${name}-error`;
  const helperTextId = `${name}-helper-text`;

  const defaultStyles = cn(
    "appearance-none block text-gray-800 resize-none border placeholder-opacity-60 w-full rounded-lg text-[1em]",
    {
      "py-[0.75em] px-4": !disablePadding,
      "focus:ring-red-700 focus:ring-2 border-red-700 focus:border-red-700":
        hasError,
      "focus:ring-teal-600 focus:ring-2": !hasError,
      "opacity-70": disabled,

      // Outlined
      "bg-white border-teal-600 focus:border-teal-700": variant === "outlined",

      // Filled
      "border-transparent focus:border-transparent":
        variant === "filled" && !hasError,
      "bg-white": variant === "filled" && backgroundColor === "white",
      "bg-gray-100": variant === "filled" && backgroundColor === "gray",
    }
  );

  return (
    <div className={classes?.root}>
      <label
        htmlFor={name}
        className={cn(
          "mb-1 block font-semibold text-gray-800",
          classes?.label,
          hideLabel && "sr-only"
        )}
      >
        {label}
        {labelSuffix === "required" ? (
          <span className="text-red-600">*</span>
        ) : null}
        {labelSuffix === "optional" ? (
          <span className="text-[0.75em] font-normal uppercase tracking-wide text-gray-600">
            {" "}
            (Optional)
          </span>
        ) : null}
      </label>

      <TextareaAutosize
        ref={ref}
        name={name}
        disabled={disabled}
        aria-invalid={hasError}
        aria-describedby={
          // eslint-disable-next-line no-nested-ternary
          hasError ? errorMessageId : helperText ? helperTextId : undefined
        }
        className={cn(!disableDefaultStyles && defaultStyles, classes?.input)}
        maxLength={maxLength}
        {...rest}
      />

      {showCounter && (
        <div className="relative">
          <div className="absolute right-0 top-1 text-xs">
            <FieldLengthCounter
              name={name}
              control={(rest as unknown as CounterProps).control}
              maxLength={maxLength}
            />
          </div>
        </div>
      )}

      {hasError && (
        <span
          id={errorMessageId}
          className={cn(
            "mt-1 block text-[0.875em] text-red-700",
            classes?.error
          )}
        >
          {errorMessage}
        </span>
      )}

      {!hasError && !!helperText && (
        <span
          id={helperTextId}
          className={cn(
            "mt-1 block text-[0.875em] text-gray-600",
            classes?.helperText
          )}
        >
          {helperText}
        </span>
      )}
    </div>
  );
}

const Textarea = forwardRef(
  (props: Props, ref: ForwardedRef<HTMLTextAreaElement>) => (
    <TextareaComponent {...props} inputRef={ref} />
  )
);
Textarea.displayName = "Textarea";

export default Textarea as typeof TextareaComponent;
