import { useRouter } from "next/router";
import { useCallback } from "react";

import useCanGoBack from "./useCanGoBack";
import useNativeContext from "./useNativeContext";
import { useSession } from "./useSession";

const useBackControl = () => {
  const { back, push } = useRouter();

  const { data: session } = useSession();
  const { deferRoutingToWebView } = session ?? {};
  const { close: closeWebView } = useNativeContext();
  const { previousPath } = useCanGoBack();
  const hasPreviousPath = !!previousPath;

  const enhancedBack = useCallback(() => {
    // If we're in a web view with deferred routing, we close the web view
    // instead of navigating back.
    if (deferRoutingToWebView) {
      return closeWebView();
    }

    // Browsers don't allow us to see if there is any history in the browser,
    // so we have to store it ourselves in session storage. :(
    if (hasPreviousPath) {
      return back();
    }

    // If the user isn't able to navigate back, we send them to the home page.
    return push("/");
  }, [deferRoutingToWebView, closeWebView, back, push, hasPreviousPath]);

  return enhancedBack;
};

export default useBackControl;
