/* eslint-disable @typescript-eslint/no-explicit-any */
import { Switch as SwitchInput } from "@headlessui/react";
import cn from "classnames";
import { FC, FocusEvent, ReactElement, useCallback } from "react";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  UseControllerReturn,
} from "react-hook-form";

interface Props {
  id?: string;
  name?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  onBlur?: (ev: FocusEvent<HTMLElement>) => void;
  disabled?: boolean;
  checkedChildren?: string;
  unCheckedChildren?: string;
  // text used for the screen reader
  srText?: string;
  size?: "default" | "small";
}

type SwitchComponent = FC<Props> & {
  Controlled: <T extends FieldValues = FieldValues>(
    props: Omit<ControllerProps<T>, "render"> & Props
  ) => ReactElement;
  Group: typeof SwitchInput.Group;
  Label: typeof SwitchInput.Label;
};

const Switch: SwitchComponent = ({
  id,
  name,
  checked,
  onChange,
  onBlur,
  disabled,
  checkedChildren: _,
  unCheckedChildren,
  srText,
  size = "default",
}) => {
  return (
    <SwitchInput
      id={id}
      className={cn(
        "relative inline-flex flex-shrink-0",
        "can-hover:cursor-pointer touch:cursor-auto rounded-full transition-colors duration-200 ease-in-out",
        "select-none items-center ring-offset-2 focus-visible:ring-2 focus-visible:ring-teal-500",
        {
          "bg-[#15AAB3]": checked,
          "bg-gray-500": !checked,
          "cursor-not-allowed": disabled,
          "h-6 w-14": size === "default",
          "h-4 w-8": size === "small",
        }
      )}
      name={name}
      checked={checked}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
    >
      {srText && <span className="sr-only">{srText}</span>}
      <span
        aria-hidden="true"
        className={cn(
          "pointer-events-none inline-block  rounded-full bg-white",
          "transform select-none shadow-lg ring-0 transition duration-200 ease-in-out",
          {
            "translate-x-9": checked && size === "default",
            "translate-x-4.5": checked && size === "small",
            "translate-x-1": !checked,
            "h-4 w-4": size === "default",
            "h-2.5 w-2.5": size === "small",
          }
        )}
      />
      <span
        role="img"
        className={cn(
          "translate-x-2 transform text-10px text-white transition duration-200 ease-in",
          {
            "visible opacity-100": !checked,
            "invisible opacity-0": checked,
          }
        )}
      >
        {unCheckedChildren}
      </span>
    </SwitchInput>
  );
};

function ControlledSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  disabled,
  unCheckedChildren,
  checkedChildren,
  size,
  ...props
}: Omit<ControllerProps<TFieldValues, TName>, "render"> & Props) {
  const render = useCallback(
    ({
      field: { ref: _, ...field },
    }: UseControllerReturn<TFieldValues, TName>) => {
      return (
        <Switch
          {...field}
          checked={!!field.value}
          disabled={disabled}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          size={size}
        />
      );
    },
    [disabled, unCheckedChildren, checkedChildren, size]
  );

  return <Controller {...props} render={render} />;
}

Switch.Controlled = ControlledSwitch;
Switch.Group = SwitchInput.Group;
Switch.Label = SwitchInput.Label;

export default Switch;
