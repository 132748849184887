import cn from "classnames";
import { Control, FieldPath, FieldValues, useWatch } from "react-hook-form";

export interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  control: Control<TFieldValues>;
  name: TFieldName;
  maxLength: number;
}

const currentLength = (value: unknown): number => {
  if (typeof value === "string") {
    return value.length;
  }
  return 0;
};

function FieldLengthCounter<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ control, name, maxLength }: Props<TFieldValues, TFieldName>) {
  const value = useWatch<TFieldValues, TFieldName>({ control, name });
  const length = currentLength(value);

  if (maxLength <= 0) {
    throw new Error("invariant violation: maxLength must be greater than 0");
  }

  const percent = length / maxLength;

  return (
    <div
      className={cn({
        "text-gray-400": percent < 0.5,
        "text-yellow-500": percent >= 0.4 && percent < 0.5,
        "text-yellow-600": percent >= 0.5 && percent < 0.7,
        "text-yellow-700": percent >= 0.7 && percent < 0.85,
        "text-yellow-800": percent >= 0.85 && percent < 0.95,
        "text-red-700": percent >= 0.95 && percent < 1,
        "text-red-800": percent === 1,
      })}
    >
      {length}
    </div>
  );
}

export default FieldLengthCounter;
