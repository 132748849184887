import { useSession } from "@core/hooks/useSession";
import { api } from "@core/services/nocd-api";
import { getQueryKey as getSubtypesQueryKey } from "@features/community-v2/hooks/useSubtypes";
import { useMutation, UseMutationResult, useQueryClient } from "react-query";

import { MemberProfile, Subtype } from "../types";
import {
  getQueryKey as getUserProfileQueryKey,
  SubtypeDTO,
  transformSubtypeDto,
} from "./useUserProfile";

export const useMutateSubtypes = (
  userId?: string
): UseMutationResult<Subtype[], Error> => {
  const { data: session } = useSession();
  const accessToken = session?.accessToken;

  const queryClient = useQueryClient();

  return useMutation(
    async (data: string[]) => {
      const url = `/v1/community/subtypes`;
      const headers = { Authorization: accessToken };
      const { data: response } = await api.post<SubtypeDTO[]>(
        url,
        { selected_subtype_ids: data },
        { headers }
      );

      return response.map((subtype) => transformSubtypeDto(subtype));
    },
    {
      onSuccess: (data: Subtype[]) => {
        queryClient.setQueryData(getSubtypesQueryKey(accessToken), data);
        queryClient.setQueryData<MemberProfile>(
          getUserProfileQueryKey(userId, accessToken),
          (profile) => {
            const subtypes = data.map((subtype) => ({
              ...subtype,
              isSelected: !!subtype.selected,
            }));
            const selectedSubtypes = subtypes.filter(
              (subtype) => subtype.isSelected
            );
            return {
              ...profile,
              subtypes,
              selectedSubtypes,
            };
          }
        );
      },
    }
  );
};
